import collect from "collect.js";
import swup from "../swup.js";
import { updateHistoryRecord } from "swup";

const mdMediaQuery = window.matchMedia("(min-width: 768px)");

export default (nodeTree) => ({
    nodeTree: [],
    mobileMenuIsOpen: false,
    md: mdMediaQuery.matches,

    // these include the currently active site and the parent nodes
    activeNodeIds: [],

    // in the mobile menu, the user can toggle the sections
    activeMobileNavNodesIds: [],

    init() {
        // First, map the node tree
        this.nodeTree = this.addParentIdsToTree(nodeTree);

        // Whenever the active ids are updates, update the mobile as well
        this.$watch("activeNodeIds", () => {
            this.activeMobileNavNodesIds = this.activeNodeIds;
        });

        // Reset the active mobile menu when closing it
        this.$watch("mobileMenuIsOpen", (mobileMenuIsOpen) => {
            if(!mobileMenuIsOpen) {
                setTimeout(() => this.activeMobileNavNodesIds = this.activeNodeIds, 300);
            }
        });

        // if we have a key set in local storage, highlight the nav
        // const lastNodeId = parseInt(localStorage.getItem('last_nav'));
        // if (lastNodeId) {
        //     console.log('setting active node from ls', lastNodeId);
        //     this.setActiveNodeIdsById(lastNodeId);
        // }

        // Set the active nodes based on the url if no nodes are active
        if (this.activeNodeIds.length === 0) {
            const url = this.getCurrentUrl();
            this.determineActiveNodeIdsByUrl(url);
        }

        // update nav on swup navigation
        swup.hooks.on("visit:start", (visit) => {
            this.mobileMenuIsOpen = false;

            const targetNodeId = visit.trigger?.el?.dataset?.nodeId;

            if (targetNodeId) {
                // The node is already set via the click event
                // But if there is no nodeid present, update the nav using the url
                // See content:replace below
                // this.setActiveNodeIdsById(targetNodeId);
                visit.meta.nodeId = targetNodeId;
            }
        });

        swup.hooks.on("content:replace", (visit) => {
            if(!visit.meta.nodeId) {
                const url = this.getCurrentUrl();
                this.determineActiveNodeIdsByUrl(url);
            }
        });

        mdMediaQuery.addEventListener("change", (e) => (this.md = e.matches));

        this.$watch("md", () => {
            this.mobileMenuIsOpen = false;
        });
    },

    // used to toggle visibility of subnav and highlight class
    nodeIsActive(nodeId) {
        return this.activeNodeIds.includes(nodeId);
    },

    nodeIsActiveMobileSubTitle(nodeId) {
        return this.activeNodeIds[this.activeNodeIds.length-1] === nodeId;
    },

    determineActiveNodeIdsByUrl(url) {
        this.activeNodeIds = this.findActiveNodeIdsByUrl(
            this.removeQueryStringFromUrl(url),
        );
    },

    navigateToNode(nodeId) {
        this.setActiveNodeIdsById(nodeId);
    },

    setActiveNodeIdsById(targetId) {
        const activeNode = this.findNodeById(parseInt(targetId));

        if (activeNode) {
            this.activeNodeIds = [
                ...activeNode.parentIds,
                activeNode.id,
                ...this.findChildrenWithSameUrl(activeNode),
            ];
        } else {
            this.activeNodeIds = [];
        }
    },

    mobileMenuSectionIsOpen(id) {
        return this.activeMobileNavNodesIds.includes(id);
    },

    toggleMobileMenu(targetId) {
        const activeNode = this.findNodeById(parseInt(targetId));

        this.activeMobileNavNodesIds = [
            activeNode.id,
            ...activeNode.parentIds,
        ];
    },

    findActiveNodeIdsByUrl(targetUrl) {
        let foundIds = [];

        const search = (nodes) => {
            collect(nodes).each((node) => {
                // Check if the current node's URL matches the target URL
                if (foundIds.length === 0 && targetUrl.startsWith(node.url)) {
                    foundIds = [
                        ...node.parentIds, // As well as the parent ids
                        node.id, // Store the ID of the first occurrence
                        ...this.findChildrenWithSameUrl(node), // and all children with the url
                    ];
                } else if (foundIds.length === 0 && node.children?.length > 0) {
                    // Continue searching in children if not found yet
                    search(node.children);
                }
            });
        };

        search(this.nodeTree);

        return foundIds;
    },

    findNodeById(targetId, tree = null) {
        tree = tree ?? this.nodeTree;
        let foundNode = null;

        collect(tree).each((node) => {
            if (foundNode) {
                return false; // Stop iterating
            }

            // Check if the current node's ID matches the target ID
            if (node.id === targetId) {
                foundNode = node; // Return the found node
            } else if (node.children && node.children.length > 0) {
                foundNode = this.findNodeById(targetId, node.children); // Return the found node from children if found
            }
        });

        return foundNode;
    },

    findChildrenWithSameUrl(parentNode) {
        if (!parentNode || !parentNode.children) {
            return []; // Return empty array if node not found or has no children
        }

        const targetUrl = parentNode.url;
        const matchingIds = [];

        // Recursive function to search through all levels of children
        const searchChildren = (children) => {
            collect(children).each((child) => {
                // Check if the child's URL matches the target URL
                if (child.url === targetUrl) {
                    matchingIds.push(child.id); // Add matching ID to the array
                }
                // If the child has its own children, search recursively
                if (child.children && child.children.length > 0) {
                    searchChildren(child.children);
                }
            });
        };

        // Start the recursive search with the immediate children of the found node
        searchChildren(parentNode.children);

        return matchingIds; // Return the collected matching IDs
    },

    getCurrentUrl() {
        return window.location.origin + window.location.pathname;
    },

    addParentIdsToTree(tree, parentIds = []) {
        return collect(tree)
            .map((node) => {
                // Create a new object with the current node's properties
                const newNode = { ...node, parentIds: [...parentIds] };

                // Recursively add parent IDs to children
                if (node.children && node.children.length > 0) {
                    newNode.children = this.addParentIdsToTree(node.children, [
                        ...parentIds,
                        node.id,
                    ]);
                }

                return newNode; // Return the modified node
            })
            .all(); // Convert the collection back to an array
    },

    removeQueryStringFromUrl(url) {
        const urlObj = new URL(url); // Create a URL object
        return urlObj.origin + urlObj.pathname; // Return pathname only
    },

    get noNodeIsActive() {
        return this.activeNodeIds.length === 0;
    },
});
