import Swup from 'swup';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupMorphPlugin from "swup-morph-plugin";
// import SwupScriptsPlugin from '@swup/scripts-plugin';

export default new Swup({
    containers: ['#swup'],
    plugins: [
        // new SwupScriptsPlugin({
        //     head: false,
        //     body: true,
        // }),
        new SwupMorphPlugin({
            containers: ["#localeSwitcher", '#mobileLocaleSwitcher'],
        }),
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin({
            prefix: 'page-',
        }),
        new SwupProgressPlugin({
            delay: 500,
        }),
    ],
});
